import $ from 'jquery';

export default function () {
  // const $nav = $('.main-nav__wrap');

  // $('.main-nav__trigger').click((e) => {
  //   e.preventDefault();
  //   $(e.currentTarget).next('.main-nav__wrap').slideToggle('fast');
  // });

  $('.js-nav__trigger').modaal({
    fullscreen: true,
    background_scroll: false,
  });
}
