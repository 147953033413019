/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
export default function () {
  const videoTrigger = document.querySelector('.js-video-trigger');
  const vid = document.getElementById('water-video');

  const init = () => {
    if (videoTrigger) {
      videoTrigger.addEventListener('click', () => {
        videoTrigger.classList.add('hide');
        vid.play();
      });
    }
  };

  init();
}
