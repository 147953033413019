/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable func-names */
import $ from 'jquery';
import objectFitImages from 'object-fit-images';

export default function () {
  $(() => { objectFitImages(); });

  $(`iframe[src*="youtube.com/embed"],
    iframe[src*="youtube-nocookie.com/embed"],
    iframe[src*="player.vimeo"]`)
    .wrap('<div class="video-container"></div>');

  $('.video-logo').waypoint(() => {
    $('.video-logo').get(0).play();
  }, {
    offset: '80%',
  });
}
