/* eslint-disable no-unused-vars */
import $ from 'jquery';
import owl from 'owl.carousel';

export default function () {
  const slider = $('.js-activity-slider').owlCarousel({
    loop: false,
    nav: false,
    margin: 30,
    items: 3,
    lazyLoadEager: 3,
    lazyLoad: true,
    animateOut: 'fadeOut',
    center: false,
    stagePadding: 1,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      900: {
        items: 3,
      },
    },
  });

  $('.js-nav-prev').on('click', () => {
    slider.trigger('prev.owl.carousel');
  });
  $('.js-nav-next').on('click', () => {
    slider.trigger('next.owl.carousel');
  });
}
